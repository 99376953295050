var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex items-center justify-center min-h-screen bg-white" },
    [
      _c(
        "div",
        {
          staticClass: "relative w-full max-w-xlg",
          staticStyle: { "min-height": "580px" },
        },
        [
          _c("div", { staticClass: "p-3" }, [
            _c("img", {
              staticClass: "pb-10 mx-auto",
              attrs: {
                src: require("@/assets/images/logo.png"),
                width: "90px",
                alt: "Nextar",
                loading: "lazy",
                "data-cy": "recover_password-nex-img",
              },
            }),
            _c("div", { staticClass: "title1 my-4" }, [
              _vm._v(" " + _vm._s(_vm.$t("unavailable.title")) + " "),
            ]),
            _c("div", { staticClass: "text1 my-4" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("unavailable.description", { time: _vm.time })
                  ) +
                  " 😊 "
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }