<template>
    <div class="flex items-center justify-center min-h-screen bg-white">
        <div class="relative w-full max-w-md" style="min-height: 580px">
            <div class="p-2">
                <img
                    class="pb-10 mx-auto"
                    src="@/assets/images/logo.png"
                    width="90px"
                    alt="Nextar"
                    loading="lazy"
                    data-cy="recover_password-nex-img"
                />
                <div class="title1 my-4">
                    {{ $t("recover_password.redefine_password_title") }}
                </div>

                <div class="text1 my-4">
                    {{ $t("recover_password.redefine_password") }}.
                </div>

                <form
                    class="w-full mt-8"
                    method="post"
                    @submit.prevent="recoverPasswordHandler"
                >
                    <input type="hidden" name="remember" value="true" />
                    <div class="rounded-md shadow-sm">
                        <div>
                            <input
                                id="user_email"
                                v-model="email"
                                maxlength="255"
                                class="nex-input"
                                required
                                type="email"
                                :placeholder="$t('email')"
                                data-cy="recover_password-email-input"
                            />
                        </div>
                    </div>

                    <div class="mt-6">
                        <submit-button-default
                            :text="$t('send')"
                            data-cy="recover_password-submit-btn"
                            :loading="loading"
                            :disabled="disableButton"
                        />
                    </div>
                    <div class="text-sm leading-5 mt-10 text-center">
                        <a
                            @click="goToLogin"
                            data-cy="recover_password-forgot-a"
                            class="font-medium cursor-pointer transition duration-150 ease-in-out text-nexblue-600 hover:text-nexblue-500 focus:outline-none focus:underline"
                        >
                            {{ $t("back") }}
                        </a>
                    </div>
                </form>
                <div>
                    <login-footer data-cy="recover_password-footer-text" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { LOGIN_VIEW, RECOVER_PASSWORD_VIEW } from "../router/constants";
import LoginFooter from "@/components/Login/LoginFooter.vue";
import SubmitButtonDefault from "@/components/SubmitButtonDefault.vue";
import { mapActions } from "vuex";
import { LOGIN_MODULE, RECOVER_PASSWORD } from "@/store/constants/login";
export default {
    name: RECOVER_PASSWORD_VIEW,
    components: {
        LoginFooter,
        SubmitButtonDefault,
    },
    data() {
        return {
            email: null,
            loading: null,
            disableButton: false,
        };
    },
    methods: {
        ...mapActions({
            recoverPasswordAction: `${LOGIN_MODULE}/${RECOVER_PASSWORD}`,
        }),
        async recoverPasswordHandler() {
            this.disableButton = true;
            this.loading = true;
            await this.recoverPasswordAction({ email: this.email })
                .catch((error) => this.recoverErrorHandling(error))
                .finally(() => (this.loading = false));

            this.$fire({
                text: this.$t("recover_password.success"),
                type: "success",
                timer: 6000,
                customClass: "alert-success-1",
            });
        },
        async goToLogin() {
            this.$router.push({ name: LOGIN_VIEW });
        },
        recoverErrorHandling(error) {
            let message = this.$t("recover_password.failed");
            this.$fire({
                text: message,
                type: "error",
                timer: 6000,
                customClass: "alert-error-1",
            });
            this.disableButton = false;
            throw new Error(JSON.stringify(error));
        },
    },
};
</script>
<style scoped>
.title1 {
    font-size: 25px;
    font-weight: 600;
    line-height: 1.25;
    color: #3c4858;
}

.text1 {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.25;
    color: #3c4858;
}
</style>
