var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex items-center justify-center min-h-screen bg-white" },
    [
      _c(
        "div",
        {
          staticClass: "relative w-full max-w-md",
          staticStyle: { "min-height": "580px" },
        },
        [
          _c("div", { staticClass: "p-2" }, [
            _c("img", {
              staticClass: "pb-10 mx-auto",
              attrs: {
                src: require("@/assets/images/logo.png"),
                width: "90px",
                alt: "Nextar",
                loading: "lazy",
                "data-cy": "recover_password-nex-img",
              },
            }),
            _c("div", { staticClass: "title1 my-4" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("recover_password.redefine_password_title")) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "text1 my-4" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("recover_password.redefine_password")) +
                  ". "
              ),
            ]),
            _c(
              "form",
              {
                staticClass: "w-full mt-8",
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.recoverPasswordHandler.apply(null, arguments)
                  },
                },
              },
              [
                _c("input", {
                  attrs: { type: "hidden", name: "remember", value: "true" },
                }),
                _c("div", { staticClass: "rounded-md shadow-sm" }, [
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email",
                        },
                      ],
                      staticClass: "nex-input",
                      attrs: {
                        id: "user_email",
                        maxlength: "255",
                        required: "",
                        type: "email",
                        placeholder: _vm.$t("email"),
                        "data-cy": "recover_password-email-input",
                      },
                      domProps: { value: _vm.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.email = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-6" },
                  [
                    _c("submit-button-default", {
                      attrs: {
                        text: _vm.$t("send"),
                        "data-cy": "recover_password-submit-btn",
                        loading: _vm.loading,
                        disabled: _vm.disableButton,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "text-sm leading-5 mt-10 text-center" },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "font-medium cursor-pointer transition duration-150 ease-in-out text-nexblue-600 hover:text-nexblue-500 focus:outline-none focus:underline",
                        attrs: { "data-cy": "recover_password-forgot-a" },
                        on: { click: _vm.goToLogin },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("back")) + " ")]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              [
                _c("login-footer", {
                  attrs: { "data-cy": "recover_password-footer-text" },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }