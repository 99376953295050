<template>
    <div class="flex items-center justify-center min-h-screen bg-white">
        <div class="relative w-full max-w-xlg" style="min-height: 580px">
            <div class="p-3">
                <img
                    class="pb-10 mx-auto"
                    src="@/assets/images/logo.png"
                    width="90px"
                    alt="Nextar"
                    loading="lazy"
                    data-cy="recover_password-nex-img"
                />
                <div class="title1 my-4">
                    {{ $t("unavailable.title") }}
                </div>
                <div class="text1 my-4">
                    {{ $t("unavailable.description" , {
                        time: time,
                    }) }} &#128522;
                </div>

               </div>
               </div>
    </div>
</template>

<script>
import { UNAVAILABLE_VIEW } from "../router/constants";
export default {
    name: UNAVAILABLE_VIEW,
    data() {
        return {
            time: null
        };
    },
    mounted(){
        this.time = process.env.VUE_APP_IS_UNAVAILABLE_TIME || "XX:XX"
    },
};
</script>
<style scoped>
.title1 {
    font-size: 25px;
    font-weight: 600;
    line-height: 1.25;
    color: #3c4858;
}

.text1 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.25;
    color: #3c4858;
}
</style>
